/* globals $ App */
import IMask from 'imask'

if (!window.App) { window.App = {} }

App.init = function () {
  // bootstrap elements
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover({ container: 'body' })
  $('div.form-group > input[type=checkbox][data-toggle^=toggle]').bootstrapToggle()
  $('div.form-group > select.selectpicker').selectpicker()
  $('.datetimepicker').datetimepicker({ format: 'MM/DD/YYYY', useCurrent: false })

  // turn off sanitization of bootstrap popover html elements (annoying)
  var myDefaultWhiteList = $.fn.popover.Constructor.DEFAULTS.whiteList
  myDefaultWhiteList.table = []
  myDefaultWhiteList.tr = []
  myDefaultWhiteList.td = []
  myDefaultWhiteList.tbody = []
  myDefaultWhiteList.thead = []

  // peity charts
  $('.peity-line').peity('line')
  $('.peity-bar').peity('bar')
  $('.peity-donut').peity('donut')

  App.applyMasks()
}

// Global app functions

// App.delay = (function () {
//   let timer = 0
//   return function (callback, ms) {
//     clearTimeout(timer)
//     timer = setTimeout(callback, ms)
//   }
// })()

App.applyMasks = function () {
  // document.getElementById('phone').addEventListener('input', function (e) {
  //   var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  //   e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  // });

  var phoneMaskOptions = { mask: '(000) 000-0000' }
  var elements = document.getElementsByClassName('phone-mask')
  Array.prototype.forEach.call(elements, function (element) {
    IMask(element, phoneMaskOptions)
  })
}

// # The following load events are now handled using
// # stimulus controller which covers both render and load events
// document.addEventListener('turbo:load', () => App.init())
// fire off turbo load event for failed form submissions
// document.addEventListener("turbo:submit-end", (event) => {
//   console.log("in submit-end")
//   if(!event.detail.success) {
//     document.dispatchEvent(new Event('turbo:load'))
//   }
// })

// document.addEventListener('turbo:before-render', function () {
//   console.log("before render")
// })

// document.addEventListener('turbo:render', function () {
//   console.log("render")
// })

// document.addEventListener('turbo:morph', function () {
//   console.log("morph")
// })

// document.addEventListener('turbo:submit-start', function () {
//   console.log("submit_start")
// })

// document.addEventListener('turbo:before-stream-render', function () {
//   console.log("before-stream-render")
// })

// document.addEventListener('turbo:visit', function () {
//   console.log("visit")
// })

document.addEventListener('turbo:before-cache', function () {
  console.log("before cache")
  // clear any popovers that may exist after "back" button
  $('.popover').remove()
  // clear any tooltips that may exist after "back" button
  $('.tooltip').remove()
  // clear any charts that may exist after "back" button
  $('svg.peity').remove()
  // clear alerts
  $('div.alert').not('.static').remove()
  // document.querySelectorAll('.alert-dismissible').forEach(e => e.remove())
  // destroy checkbox toggles
  $('input[type=checkbox][data-toggle^=toggle]').bootstrapToggle('destroy')
  // clear bootstrap-select
  $('select.selectpicker').selectpicker('destroy').addClass('selectpicker')
  // Manually tear down bootstrap modals before caching. If turbolinks
  // caches the modal then tries to restore it, it breaks bootstrap's JS.
  // We can't just use bootstrap's `modal('close')` method because it is async.
  // Turbolinks will cache the page before it finishes running.
  if (document.body.classList.contains('modal-open')) {
    $('.modal')
      .hide()
    $('.modal-backdrop').remove()
    $('body').removeClass('modal-open')
  }
})
